import { unref as _unref, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-493f49b4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  id: "login-cont"
};
const _hoisted_2 = {
  id: "login"
};
import { useStore } from 'vuex'; // 引入useStore 方法
import { computed, watch, onMounted } from 'vue';
export default {
  __name: 'Login',
  props: ['showLogin'],
  setup(__props, {
    expose
  }) {
    const props = __props;
    const showLogin = computed(() => {
      return props.showLogin;
    });
    const store = useStore(); // 该方法用于返回store 实例

    //工具函数，直接调用即可，可确保初始化操作在sdk导入成功后进行
    function loadScript(src, cb) {
      var head = document.head || document.getElementsByTagName('head')[0];
      var script = document.createElement('script');
      cb = cb || function () {};
      script.type = 'text/javascript';
      script.src = src;
      if (!('onload' in script)) {
        script.onload = script.onreadystatechange = function () {
          if (this.readyState && (this.readyState === 'loaded' || this.readyState === 'complete')) {
            this.onreadystatechange = null;
            cb(script);
          }
        };
      } else {
        script.onload = function () {
          this.onload = null;
          cb(script);
        };
      }
      head.appendChild(script);
    }
    let baseUrl = '',
      env = process.env.VUE_APP_ENV,
      config = {
        env: env === 'dev' ? 'st0' : env,
        version: 'v1.1.0'
      };
    if (env === 'prod') {
      // 线上
      baseUrl = `//new-passport-plugin-version.hao184.com/uc/sdk/prod/${config.version}`;
    } else {
      baseUrl = `//new-passport-plugin-version-test.hao184.com/uc/sdk/${config.env}/${config.version}`;
    }
    window.loginInstance = null;
    function loginFn(init) {
      const configSDK = {
        env: config.env,
        // sdk接入的环境
        version: config.version,
        //sdk接入的版本
        mid: 'AIPAINT',
        // mid
        dialog: true,
        // 是否弹窗
        el: document.getElementById('login'),
        //容器
        hasAvatar: true,
        localRefresh: true,
        //用于登录成功后局部刷新
        localRefreshElId: 'login',
        //用于登录成功后局部刷新,容器id
        localRefreshElParentId: 'login-cont',
        //用于登录成功后局部刷新,容器id父级
        serviceAgreement: 'https://zhushou.2345cdn.net/agreement/p_13/agree_220.html',
        // 自定义服务协议链接
        privacyPolicy: 'https://zhushou.2345cdn.net/agreement/p_13/agree_221.html',
        // 自定义隐私政策链接
        // 自定义皮肤样式
        style: {
          //最外层容器的样式 （背景样式）
          wrapper: {
            width: '370px',
            height: '400px',
            background: '#141416',
            boxShadow: '0 2px 10px 0 #485459',
            borderRadius: '12px'
          },
          // // 输入框
          input: {
            width: '310px',
            height: '40px',
            borderRadius: '12px !important',
            border: ' 1px solid #5B5E6B',
            fontsize: '12px',
            fontfamily: 'MicrosoftYaHei',
            color: 'rgba(255,255,255,0.78)',
            lineheight: '16px'
          },
          // // 验证码按钮
          smsCodeBtn: {
            color: '#21ECF8'
          },
          //登录按钮
          login: {
            // color: '#e6df0c',
            // background: '#278'
          },
          // 协议隐私
          servicePrivacy: {
            color: '#A1A1A1'
          },
          // 协议隐私-链接
          servicePrivacyLink: {
            color: '#21ECF8'
          }
        },
        registeredButtonCallback: function (res) {
          // 点击“注册”回调
        },
        loginButtonCallback: function (res) {
          // 点击“登录”回调
        },
        getUserInfo: function (res) {
          //登录成功回调
          // res.userInfo 用户信息
          // res.ticket 登录凭证
          store.commit('userInfo', res.userInfo);
          store.commit('ticket', res.ticket);
          store.commit('isLogined', true);
          store.commit('isCheckLogin', false);
          store.commit('isLogout', false);
        },
        verifyErr: function () {
          const dom = document.getElementsByClassName('ant-tabs-tab-inner')[0];
          // dom.innerText = '验证码登录/注册'
          dom.innerText = '微信登录/注册';
          console.log('校验登录状态失败回调');
          store.commit('isLogined', false);
          store.commit('userInfo', '');
          store.commit('ticket', '');
          store.commit('isCheckLogin', false);
          if (init) return;
          reportFn('login_show', 'click');
        }
      };
      window.loginInstance = new Login2345(configSDK);
    }
    loadScript(baseUrl + '/login.umd.js', function () {
      loginFn(true);
    });
    const logoutFn = () => {
      window.loginInstance.dropOut().then(function () {
        store.commit('isLogined', false);
        store.commit('userInfo', '');
        store.commit('ticket', '');
        store.commit('isCheckLogin', false);
      });
    };
    expose({
      logoutFn,
      loginFn
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createElementVNode("div", _hoisted_2, null, 512), [[_vShow, _unref(showLogin)]])]);
    };
  }
};