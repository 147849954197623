export default {
    exampleLists: [
        [
            '近距离照片，年轻女性，粉色长发',
            '1个女孩，蝴蝶发带，大眼睛，沙滩，阳光，多云的天空',
            '成熟男性，城市背景，白色胡须，表情严肃',
            '女孩，汉服，细碎亮光，脸部精致，森林',
        ],
        [
            '可爱女孩，兔子耳朵，银色短发，害羞',
            '白衣，男孩，斯文，棕色短发，微笑',
            '可爱，光滑的长发，纤细的眼睛，发光脸女人，纯度的颜色，天空平坦',
            '少女，脸部细致，麻花辫，花海，色彩艳丽',
        ],
        [
            '极致细腻的美少女，精致的五官，粉色凌乱发，迷人气质',
            '漂亮的美少女，柔美，花形耳环，水晶项链，精美的头饰',
            '帅气的少年，白色汉服，束发，发带，帅气的五官，精致的头发，精致的眼睛',
            '熊猫宝宝，圆圆的脑袋，又大又圆的眼睛，吐舌头，微笑，圆滚滚的身材，呆萌',
        ],
    ],
    referWord: [
        {
            name: '人物',
            children: ['女孩', '男孩', '单人', '可爱的女孩', '美少女', '可爱', '正太', '小女孩', '小男孩', '辣妹', '成熟女性', '天使', '萝莉', '女仆', '护士', '学生', '吸血鬼', '巫女', '人偶', '妖精', '小精灵']
        },
        {
            name: '头发',
            children: ['短发', '长发', '有长有短', '金色', '棕色', '黑色', '粉色', '红色', '银色', '白色', '蓝色', '渐变', '多色', '闪亮', '头发下垂', '湿头发', '头发梳起', '交叉刘海', '波浪头', '卷发', '呆毛', '蝴蝶结', '头花', '发箍', '洛丽塔发带']
        },
        {
            name: '五官',
            children: ['淡淡腮红', '面纹', '凝视', '看旁边', '兔子耳朵', '猫耳', '浓妆', '长睫毛', '异色瞳', '眼下痣', '水汪汪的大眼', '爱心瞳孔']
        },
        {
            name: '表情',
            children: ['可爱表情', '微笑', '兴奋', '害羞', '脸红', '失神', '哭', '悲伤的', '生气的', '半闭眼睛', '做鬼脸']
        },
        {
            name: '服装',
            children: ['帽子', '衬衫', '学校制服', '礼服长裙', '长袍', '裙子', '水手服', '西装', '婚纱', '围裙', '露单肩', '运动服', '哥特洛丽塔风格', '花边', '睡衣']
        },
        {
            name: '动作',
            children: ['歪头', '向上看', '向下看', '手放在嘴边', '嘘手势', '张开手', '单手叉腰', '举手', '拉头发', '回头', '手放脑后', '闻']
        },
    ],
    screenSize: [
        {
            size: '3:4',
            name: '竖图',
            type: 'vertical',
            actived: true,
            width: 576,
            height: 768
        },
        {
            size: '4:3',
            name: '横图',
            type: 'horizontal',
            actived: false,
            width: 768,
            height: 576
        },
        {
            size: '1:1',
            name: '小头像',
            type: 'square',
            actived: false,
            width: 512,
            height: 512
        },
        {
            size: '1:1',
            name: '精致头像',
            type: 'square',
            actived: false,
            width: 1024,
            height: 1024
        },
    ],


}