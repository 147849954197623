import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createBlock as _createBlock } from "vue";
import api from '../api/index.js';
import { onBeforeUnmount, reactive, ref } from 'vue';
import { useThrottleFn } from '@vueuse/core';
import { ElMessage } from 'element-plus';
export default {
  __name: 'BindPhone',
  emits: ['close', 'success'],
  setup(__props, {
    expose,
    emit
  }) {
    const formRef = ref();
    const form = reactive({
      phone: '',
      smsCode: ''
    });
    // 获取验证码
    const getSmscodeFn = () => {
      console.log('获取验证码');
      formRef.value.validateField('phone', isValid => {
        if (isValid) {
          console.log('手机号验证通过');
          // 发送验证码
          if (smsData.sendLoading || smsData.isSend) return;
          sendSmscode();
        } else {
          console.log('手机号验证不通过');
        }
      });
    };

    // const getSmscodeFn = useThrottleFn(getSmscode, 2000)

    const smsData = reactive({
      isSend: false,
      //是否已发送
      seconds: 60,
      // 60s倒计时
      sendLoading: false,
      //发送按钮loading状态
      sendText: '获取验证码',
      //发送按钮文字
      timer: null //定时器
    });

    const timer = ref(null);
    const sendSmscode = () => {
      smsData.sendLoading = true;
      clearInterval(timer.value);
      api.syncSendSmsCode({
        phone: form.phone + ''
      }).then(res => {
        smsData.sendText = '重新获取' + smsData.seconds + 's';
        smsData.isSend = true;
        timer.value = setInterval(() => {
          smsData.seconds--;
          smsData.sendText = '重新获取' + smsData.seconds + 's';
          if (smsData.seconds <= 0) {
            clearInterval(timer.value);
            smsData.seconds = 60;
            smsData.sendText = '获取验证码';
            smsData.isSend = false;
          }
        }, 1000);
      }).catch(({
        message
      }) => {
        ElMessage({
          message,
          type: 'error'
        });
      }).finally(() => {
        smsData.sendLoading = false;
      });
    };
    let isSubmitLoading = ref(false);
    const submitFormFn = formEl => {
      if (!formEl || isSubmitLoading.value) return;
      formEl.validate(valid => {
        if (valid) {
          console.log('submit!');
          isSubmitLoading.value = true;
          api.syncData({
            phone: form.phone + '',
            code: form.smsCode + ''
          }).then(res => {
            // 关闭弹框  提示 正在同步中，请稍后
            ElMessage({
              message: '正在同步中，请稍后',
              type: 'success'
            });
            emit('close');
            emit('success');
          }).catch(({
            message
          }) => {
            ElMessage({
              message,
              type: 'error'
            });
            // emit('close')
            // emit('success')
          }).finally(() => {
            isSubmitLoading.value = false;
          });
        }
      });
    };
    const resetForm = () => {
      if (!formRef) return;
      clearInterval(timer.value);
      isSubmitLoading.value = false;
      smsData.isSend = false;
      smsData.sendText = '获取验证码';
      formRef.value.resetFields();
    };
    expose({
      resetForm
    });
    onBeforeUnmount(() => {
      clearInterval(timer.value);
    });
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_form = _resolveComponent("el-form");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createBlock(_component_el_form, {
        ref_key: "formRef",
        ref: formRef,
        model: form,
        class: "demo-ruleForm"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "",
          prop: "phone",
          rules: [{
            required: true,
            message: '手机号不可为空',
            trigger: 'blur'
          }, {
            type: 'number',
            message: '请输入正确的手机号',
            trigger: 'blur'
          }]
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: form.phone,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => form.phone = $event),
            modelModifiers: {
              number: true
            },
            placeholder: "请输入手机号",
            clearable: "",
            type: "text",
            maxlength: "11",
            autocomplete: "off"
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "",
          prop: "smsCode",
          rules: [{
            required: true,
            message: '验证码不可为空',
            trigger: 'blur'
          }, {
            type: 'number',
            message: '验证码错误，请重新输入',
            trigger: 'blur'
          }, {
            pattern: /^([0-9]{4}|[0-9]{6})$/,
            message: '验证码错误，请重新输入',
            trigger: 'blur'
          }]
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: form.smsCode,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => form.smsCode = $event),
            modelModifiers: {
              number: true
            },
            placeholder: "请输入验证码",
            clearable: "",
            type: "text",
            maxlength: "6",
            autocomplete: "off"
          }, {
            append: _withCtx(() => [_createElementVNode("div", {
              class: _normalizeClass(["c-p sms-btn", {
                'is-send': smsData.isSend
              }]),
              onClick: _cache[1] || (_cache[1] = $event => getSmscodeFn())
            }, _toDisplayString(smsData.sendText), 3)]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }, 8, ["rules"]), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createElementBlock("div", {
            class: "submit-btn t-c c-p",
            type: "primary",
            onClick: _cache[3] || (_cache[3] = $event => submitFormFn(formRef.value))
          }, [_createTextVNode(" 提 交 ")])), [[_directive_loading, _unref(isSubmitLoading)]])]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"]);
    };
  }
};